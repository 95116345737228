var animationCount = (function () {
	var verifyCount = function(){
		$('.counter').each(function() {
			var $this 		= $(this);
			var countTo 	= $this.attr('data-count');

			if(this.getBoundingClientRect().top < (window.innerHeight - 100)){
				$({countNUm: $this.text()}).animate({
					countNum: countTo
				}, {
					duration	: 1000,
					easing		: 'linear',
					step 		: function() {
						$this.text(Math.floor(this.countNum));
						$this.removeClass('counter');
					},
					complete 	: function () {
						$this.text(this.countNum);
						
					}
				});
			}
		});
	}
	var jaCount = false;

	verifyCount();

	$(window).scroll(function(){
		if(jaCount) return;

		setTimeout(function(){
			jaCount = false;
		}, 100);

		verifyCount();
	});
})();