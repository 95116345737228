$('[data-scroll-to]').click(function(e){
	e.preventDefault();

	var target = $(this).data('scroll-to');
	var targetPosition = $(target).offset();

	$('body, html').animate({
		scrollTop : targetPosition.top
	}, 1000);	

	$('.main-navigation').removeClass('active');
	$('.bg-menu').fadeOut(400);
	$('.mbl-menu').toggleClass('active');
});