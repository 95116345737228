const popup = document.querySelector('.msg__cookies');
const btn   = popup.querySelector('.btn');

// PEGANDO O DIA ATUAL
const HOJE = today();

function today() {
    const data = new Date();

    let dia  = data.getDay();
    let mes  = data.getMonth() + 1;
    let ano  = data.getFullYear();

    mes = mes < 10 ? `0${mes}` : mes;

    return `${dia}/${mes}/${ano}`;
}

function removePopup() {
    popup.parentNode.removeChild(popup);
}

// Checando se os cookies foram aceitos
let cookieMessage = localStorage.getItem('cookies');// string | undefined

if(cookieMessage) {
    // Transformando a string retornada em um objeto
    cookieMessage = JSON.parse(cookieMessage);

    if(cookieMessage.data === HOJE) {

        removePopup();

    }

}else {

    popup.classList.add('msg__cookies--shown');
}

// Criando o evento que vai registrar os coockies
btn.addEventListener('click', event => {
    event.preventDefault();

    const cookiesRegister = {
        data: HOJE,
        aceito: new Date()
    };

    localStorage.setItem('cookies', JSON.stringify(cookiesRegister));
    popup.classList.add('msg__cookies--hide');

});

// removendo o popup quando terminar a animacao de HIDE;
popup.addEventListener('animationend', () => {
    if(popup.classList.contains('msg__cookies--hide')) {
        removePopup();
    }
});
