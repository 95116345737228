(function() {
	Object.defineProperty(window, 'midiaSize', {
		enumerable: false,
		get: function() {
			let w = window.innerWidth;
			let size = 'xs';

			if(w < 576){
				size = 'xs';
			}

			if(w >= 576){
				size  = 'sm';
			}

			if(w >= 768){
				size = 'md';
			}

			if(w >= 992){
				size = 'lg';
			}

			if(w >= 1200){
				size = 'xl';
			}

			return size;
		}
	});
})();